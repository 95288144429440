<template>
  <mini-profile-menu/>
  <div class="chats w-80 mx-auto d-flex flex-column">
    <header-component :text="'Список ваших чатов'"/>
    <ChatComponent v-for="chat in chats" :chat="chat" :key="chat.task"/>
  </div>
  <main-menu/>
</template>

<script>
import MiniProfileMenu from "@/items/MiniProfileMenu";
import MainMenu from "@/items/MainMenu";
import ChatComponent from "@/components/chat/Chat";
import Functions from "@/assets/js/functions";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "ChatsPage",
  components: {HeaderComponent, ChatComponent, MainMenu, MiniProfileMenu},
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
    this.getChats();
  },
  mounted() {

    clearInterval(Functions.interval)
    Functions.interval = setInterval(() => {
      this.getChats()
    }, 3000)
  },
  methods: {
    getChats() {
      let s = this;
      Functions.fetch("chat/list", null, function (json) {
        if (json.type == "success") {
          if (!Functions.empty(json.data)) {
            s.chats = json.data;
          }
        }
      })
    }
  },
  data() {
    return {
      chats: []
    }
  }
}
</script>

<style scoped>
.chats {
  margin-bottom: 120px;
}

.header_gray {
  color: #858585;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
</style>