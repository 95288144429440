<template>
  <MiniProfileMenu/>
  <div v-if="otherTasks.length!==0" class="tasks w-80 mx-auto d-flex flex-column">
    <header-component :text="'Список выполняемых заявок'"/>
    <other-task v-for="task in otherTasks" :task="task" :key="task.id"/>
  </div>
  <div v-if="myTasks.length!==0" class="mytasks w-80 mx-auto d-flex flex-column">
    <header-component :text="'Список ваших заявок'"/>
    <my-task v-for="task in myTasks" :task="task" :key="task.id"/>
  </div>
  <div v-else class="h-100 w-100 d-flex flex-center">
    <h3>Список заявок пока пуст</h3>
  </div>
  <MainMenu/>

</template>

<script>
import MainMenu from "@/items/MainMenu";
import MiniProfileMenu from "@/items/MiniProfileMenu";
import Functions from "@/assets/js/functions";
import MyTask from "@/components/tasks/MyTask";
import headerComponent from "@/components/HeaderComponent";
import OtherTask from "@/components/tasks/OtherTask";

export default {
  name: "MainPage",
  components: {OtherTask, MyTask, MiniProfileMenu, MainMenu, headerComponent},
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
  },
  mounted() {
    this.getOtherTasks();
    this.getMyTasksList();
  },
  data() {
    return {
      myTasks: [],
      otherTasks: [],
    }
  },
  methods: {
    getMyTasksList() {
      let s = this;
      Functions.fetch("task/mytasks",
          {
            limit: s.myTasks.length,
            count: "25"
          }, function (json) {

            if (!Functions.empty(json.data))
              json.data.forEach(el => {
                s.myTasks.push(el)
              })
          });
    },
    getOtherTasks() {
      let s = this;
      Functions.fetch("task/othertasks",
          {
            limit: s.myTasks.length,
            count: "25"
          }, function (json) {

            if (!Functions.empty(json.data))
              json.data.forEach(el => {
                s.otherTasks.push(el)
              })
          });
    }
  }
}
</script>

<style scoped>
.header_gray {
  color: #858585;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.mytasks {
  padding-bottom: 120px;
}
</style>