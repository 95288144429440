<template>
  <div class="task">
    <div class="taskHeader">{{ task.title }}</div>
    <div class="taskUsername" v-if="!isEmptyUsername">Имя: {{ task.username }}</div>
    <div class="taskAddress">Адрес: {{ task.address }}</div>
    <div class="taskPrice">{{ task.price }} руб.</div>

    <button v-if="Number(task.status)!=3&&Number(task.status)!=2&&Number(task.status)!=0" @click="acceptTask"
            class="acceptTask"><img
        :src="require('@/assets/images/gal.svg')"></button>
    <button v-if="Number(task.status)!=3&&Number(task.status)!=2" @click="cancelTask" class="cancelTask"><img
        :src="require('@/assets/images/close.svg')"></button>
    <button v-if="Number(task.status)==2&&task.grade==null" @click="gradeTask" class="gradeTask"><img
        :src="require('@/assets/images/zwezda.svg')"></button>
  </div>
</template>

<script>
import Functions from "@/assets/js/functions";

export default {
  name: "MyTask",
  props: ["task"],
  data() {
    return {}
  },
  methods: {
    isEmptyUsername() {
      return Functions.empty(this.task.username)
    },
    acceptTask() {
      let s = this;
      Functions.ajax("task/close", {
        task: s.task.id
      }, (json) => {
        if (json.type == "success") {
          s.task.status = 2;
        } else {
          Functions.sendAnswer(json.msg, "error");
        }
      })
    },
    cancelTask() {
      let s = this;
      Functions.ajax("task/cancel", {
        task: s.task.id
      }, (json) => {
        if (json.type == "success") {
          s.task.status = 3;
        } else {
          Functions.sendAnswer(json.msg, "error")

        }
      })
    },
    gradeTask() {
      this.$router.push("/task/grade/" + this.task.id);
    }
  }
}
</script>

<style scoped>
.task {
  position: relative;
  box-shadow: 0px 8px 18px 2px #0000001A;
  background: #222222;
  padding: 31px 15px 31px 34px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  margin-bottom: 37px;
}

.taskHeader {
  color: #F0F0F0;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
}

.taskUsername {
  color: #F0F0F0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
}

.taskAddress {
  color: #F0F0F0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
}

.acceptTask {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 49px;
  height: 49px;
  z-index: 1;
  padding: 12px;
  border-radius: 50%;
  background: linear-gradient(143.59deg, #8AA9A6 -43.44%, #49A562 42.24%, #49A562 84.41%);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 9px;
  bottom: -30px;
  outline: none;
  border: none;
}

.cancelTask {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;
  background: linear-gradient(144.29deg, #FF006B 13.01%, #C6000C 45.25%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  right: -10px;
  top: -10px;
  outline: none;
  border: none;

}

.acceptTask img, .cancelTask img, .gradeTask img {
  width: 24px;
  height: 24px;
}

.gradeTask {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 49px;
  height: 49px;
  z-index: 1;
  padding: 12px;
  border-radius: 50%;
  background: linear-gradient(90.59deg, #FFDD00, #FBB034);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 9px;
  bottom: -30px;
  outline: none;
  border: none;

}

.taskPrice {
  background: #FFC700;
  box-shadow: 2px 4px 10px 0px #00000040 inset;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 32px 0px;
  color: #222222;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  width: 128px;
  height: 29px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>