import {createApp} from 'vue'
import App from './App.vue'
import AuthPage from "@/pages/AuthPage";
import {createRouter, createWebHistory} from "vue-router/dist/vue-router";
import "@/assets/css/allowstyle.css"
import "@/assets/css/style.css"
import RegisterPage from "@/pages/RegisterPage";
import ResetPasswordPage from "@/pages/ResetPasswordPage";
import MainPage from "@/pages/MainPage";
import AddTaskPage from "@/pages/AddTaskPage";
import YmapPlugin from 'vue-yandex-maps'
import TaskPage from "@/pages/TaskPage";
import AllTasksPage from "@/pages/AllTasksPage";
import ChatsPage from "@/pages/ChatsPage";
import ChatPage from "@/pages/ChatPage";
import ProfilePage from "@/pages/ProfilePage";
import './registerServiceWorker'
import Functions from "@/assets/js/functions";
import GradePage from "@/pages/GradePage";


const routes = [{path: "/", component: MainPage}, {path: "/auth", component: AuthPage}, {
    path: "/register", component: RegisterPage
}, {path: "/reset", component: ResetPasswordPage}, {path: "/add", component: AddTaskPage}, {
    path: "/task/:id",
    component: TaskPage
}, {
    path: "/tasks",
    component: AllTasksPage
}, {
    path: "/chats",
    component: ChatsPage
}, {
    path: "/chat/:id",
    component: ChatPage
},
    {
        path: "/task/grade/:id",
        component: GradePage
    }, {
        path: "/profile",
        component: ProfilePage
    }]

const settings = {
    apiKey: 'fbb3cec9-7c52-4009-b4b0-597b8861a89d',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
}


const Router = createRouter({history: createWebHistory(), routes: routes})
const app = createApp(App)
app.use(Router)
app.use(YmapPlugin, settings)
app.mount("#app")

if (Functions.getCookie("token") !== undefined)
    import("@/firebase-messaging-sw");


