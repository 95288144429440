<template>
  <div class="main_menu">

    <div class="icon main_icon" v-on:click="()=>{$router.push('/')}">
      <img :src="getMainSrc()">
    </div>
    <div class="icon tasks_icon" v-on:click="()=>{$router.push('/tasks')}">
      <img :src="getTasksSrc()">
    </div>

    <div class="icon add_task">
      <div class="add_task_button" @click="()=>{$router.push('/add')}">
        <img :src="require('@/assets/images/mainMenu/addTask.svg')">
      </div>
    </div>

    <div class="icon chats_icon d-flex flex-center" v-on:click="()=>{$router.push('/chats')}">
      <div style="position: relative">
        <img :src="getChatsSrc()">
        <div v-if="messages" class="notify">
        </div>
      </div>
    </div>
    <div class="icon profile_icon" v-on:click="()=>{$router.push('/profile')}">
      <img :src="getProfileSrc()">
    </div>

  </div>
</template>

<script>
import Functions from "@/assets/js/functions";

export default {
  name: "MainMenu",
  data() {
    return {
      messages: false
    }
  },
  methods: {
    getMainSrc() {
      return this.$route.path == "/" ? require("@/assets/images/mainMenu/activeMain.svg") : require("@/assets/images/mainMenu/main.svg")
    },
    getTasksSrc() {
      return ["/tasks", "/mytasks", "/currenttasks"].indexOf(this.$route.path) !== -1 ? require("@/assets/images/mainMenu/activeTasks.svg") : require("@/assets/images/mainMenu/tasks.svg");
    },
    getChatsSrc() {
      return ["/chats"].indexOf(this.$route.path) !== -1 ? require("@/assets/images/mainMenu/activeChat.svg") : require("@/assets/images/mainMenu/Chat.svg");
    },
    getProfileSrc() {
      return ["/profile"].indexOf(this.$route.path) !== -1 ? require("@/assets/images/mainMenu/activeProfile.svg") : require("@/assets/images/mainMenu/profile.svg")
    },
    messagesCount() {
      let s = this;
      Functions.fetch("getcountnewmewssages", null, function (json) {

        if (json.type == "success") {
          if (json.data.count != 0 && json.data.count != null)
            s.messages = true; else s.messages = false;
        }
      })
    }
  },
  mounted() {
    clearInterval(Functions.interval)
    Functions.interval = setInterval(() => {
      this.messagesCount();
    }, 3000)
    this.messagesCount()
  },


}
</script>

<style scoped>
.main_menu {
  height: 97px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("@/assets/images/mainMenu/background.png");
  z-index: 2;
}

.icon {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.add_task_button {
  position: absolute;
  width: 65px;
  height: 65px;
  bottom: 70%;
  background: linear-gradient(144.29deg, rgba(255, 199, 0, 0.88) 13.01%, #FFC700 45.25%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.icon {
  margin: auto;
}

.notify {
  position: absolute;
  right: -3px;
  top: -3px;
  width: 8px;
  height: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #FFC700;
  border-radius: 50%;

}
</style>