<template>
  <div class="background">
    <div id="map">
    </div>
    <div class="fields w-75 mx-auto my-auto d-flex flex-column">
      <input-field :params="fields.titleField"/>
      <input-field :params="fields.phoneField"/>
      <address-field :params="fields.addressField"/>
      <text-area-field :params="fields.descriptionField"/>
      <div class="sumField d-flex flex-center">
        <div class="text mx-auto">Желаемое время</div>
        <div class="field-yellow d-flex flex-center ml-auto">
          <input-field :params="fields.timeField"/>
        </div>
      </div>
      <div class="sumField d-flex flex-center">
        <div class="text mx-auto">Сумма</div>
        <div class="field-yellow d-flex flex-center ml-auto">
          <input-field :params="fields.priceField"/>
          <div class="currency">Руб</div>
        </div>
      </div>
      <default-button class="mx-auto" :params="buttons.createButton"/>
    </div>
    <div class="close" @click="()=>{$router.push('/')}"><img :src="require('@/assets/images/close.svg')"></div>
  </div>

</template>

<script>
import InputField from "@/components/fields/InputField";
import TextAreaField from "@/components/fields/TextAreaField";
import AddressField from "@/components/fields/AddressField";
import defaultButton from "@/components/buttons/DefaultButton";
import "@/assets/css/fullscreen.css";
import Functions from "@/assets/js/functions";
import {loadYmap} from 'vue-yandex-maps'

export default {
  name: "AddTaskPage",
  components: {AddressField, TextAreaField, InputField, defaultButton},
  data() {
    return {
      settings: Functions.settings,
      fields: {
        titleField: {
          title: "Заголовок",
          value: "",
          error: ""
        },
        phoneField: {
          title: "Телефон",
          type: "tel",
          value: localStorage.getItem("phone"),
          error: ""
        },
        addressField: {
          title: "Адрес",
          value: "",
          coords: null,
          error: "",
        },
        timeField: {
          type: "time",
          value: "",
          error: ""
        },
        descriptionField: {
          title: "Описание",
          value: "",
          error: ""
        },
        priceField: {
          value: "0",
          type: "number"
        }
      },
      buttons: {
        createButton: {
          value: "Создать заявку",
          action: () => {
            this.createTask()
          }
        }
      }
    }
  },
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
  },
  async mounted() {
    await loadYmap(Functions.settings);
    // eslint-disable-next-line no-undef
    Functions.map = new ymaps.Map('map', {
      center: [55.751574, 37.573856],
      zoom: 15,
      controls: ['zoomControl']
    }, {
      searchControlProvider: 'yandex#search'
    })

    let s = this;
    Functions.map.events.add('click', (item) => {
      var coords = item.get('coords');
      // eslint-disable-next-line no-undef
      ymaps.geocode(coords).then(function (res) {
        var firstGeoObject = res.geoObjects.get(0);
        s.fields.addressField.coords = firstGeoObject.geometry.getCoordinates();
        s.fields.addressField.value = firstGeoObject.getAddressLine();
        s.updateMap()
      })
    })
    Functions.checkPhone();
  },

  methods: {
    createTask() {
      let s = this;
      this.fields.titleField.error = "";
      this.fields.phoneField.error = "";
      this.fields.addressField.error = "";
      this.fields.priceField.error = "";
      this.fields.timeField.error = "";
      this.fields.descriptionField.error = "";
      if (Functions.empty(this.fields.titleField.value)) {
        this.fields.titleField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.phoneField.value)) {
        this.fields.phoneField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.addressField.value)) {
        this.fields.addressField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.addressField.coords)) {
        this.fields.addressField.error = "Выберите вариант из списка"
        return;
      } else if (Functions.empty(this.fields.priceField.value) && this.fields.priceField.value != 0) {
        this.fields.priceField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.descriptionField.value)) {
        this.fields.descriptionField.error = "Поле не может быть пустым"
        return;
      } else {
        let phone = this.fields.phoneField.value.replace("+7", "").replace("(", "").replace(")", "").replace("-", "").replaceAll(/\s/g, '').trim();
        if (phone.length == 11) {
          phone = phone.substring(0, phone.length - 1);
        }

        if (this.fields.phoneField.value.length < 10) {
          this.fields.phoneField.error = "Введите телефон полностью"
          return;
        }
        Functions.ajax("task/create", {
          title: s.fields.titleField.value,
          content: s.fields.descriptionField.value,
          address: s.fields.addressField.value,
          coords: s.fields.addressField.coords.join(","),
          pleaseTime: s.fields.timeField.value,
          price: s.fields.priceField.value,
          phone: phone
        }, function (json) {
          if (json.type == "success") {
            s.$router.push("/");
            Functions.sendAnswer("Заявка успешно создана", "success")
          } else {
            Functions.sendAnswer(json.msg, "error")
          }
        })
      }
    },
    async updateMap() {
      // eslint-disable-next-line no-undef
      if (ymaps == undefined) {
        await loadYmap(Functions.settings);
      }
      Functions.map.panTo(this.fields.addressField.coords);

      try {

        if (Functions.mark == null) {
          if (this.fields.addressField.coords != null) {
            // eslint-disable-next-line no-undef,vue/no-mutating-props
            Functions.mark = new ymaps.GeoObject({
              // Описание геометрии.
              geometry: {
                type: "Point",
                coordinates: this.fields.addressField.coords
              },
              // Свойства.
              properties: {
                // Контент метки.
              }
            }, {
              // Опции.
              // Иконка метки будет растягиваться под размер ее содержимого.
              preset: 'islands#blackStretchyIcon',
              // Метку можно перемещать.
              draggable: true
            });

            Functions.map.geoObjects.add(Functions.mark);
          }
        } else {
          Functions.mark.geometry.setCoordinates(this.fields.addressField.coords);
        }
      } catch (ex) {
        console.log(ex)
      }
    }

  },

}
</script>

<style>


.field-yellow .field_input {
  background-color: transparent !important;
  text-align: center;
  width: 100%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.currency {
  text-align: center;
  width: 50%;
}
</style>

<style scoped>
#map {
  height: 25vh;
}

.sumField {
  background: #E8E8E8;
  border-radius: 12px;
  margin-bottom: 12px;

}

.field-yellow {
  background: #FFC700;
  border-radius: 12px;
  width: 40%;
}

.field-yellow .field {
  background: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 50%;
  width: 100%;
}


.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg_dark_color);
}

.ymap-container {
  height: 100%;
}

.close {
  width: 24px;
  height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background: linear-gradient(144.29deg, #FF0000 13.01%, #D70000 45.25%);
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.close img {
  width: 100%;
  height: 100%;
}
</style>