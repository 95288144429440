<template>
  <div @click="toChat" class="chat">
    <div class="taskTitle">
      "{{ chat.title }}"
    </div>
    <div v-if="ifNotification" class="notification">

    </div>
  </div>
</template>

<script>
export default {
  name: "ChatComponent",
  props: ["chat"],
  methods: {
    toChat() {
      this.$router.push("/chat/" + this.chat.task)
    }
  },
  data() {
    return {
      ifNotification: Number(this.chat.newmessages) == 1 ? true : false
    }
  }
}
</script>

<style scoped>
.chat {
  box-shadow: 0px 8px 18px 2px #0000001A;
  background: #222222;
  height: 80px;
  border-radius: 32px 32px 32px 0px;
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #F0F0F0;
  font-size: 14px;
  text-align: center;
  position: relative;
}

.taskTitle {
  margin: auto;
}

.notification {
  right: 0;
  top: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FFC700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>