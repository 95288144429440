<template>
  <div class="chatHead d-flex">
    <div class="header_gray  mr-auto my-auto">{{ title }}</div>
    <div class="close" @click="()=>{$router.push('/chats')}"><img :src="require('@/assets/images/close.svg')"></div>
  </div>
  <div class="messages">
    <message-component v-for="message in messages" :key="message"
                       :name="message.position==='r'?'Вы': (Number(message.creator) === 1 ? chat.creator_name : chat.accept_name)"
                       :message="message"/>
  </div>
  <div class="sendBlock">
    <div class="inputSend">
      <div class="screpka">
        <!--        <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--          <g filter="url(#filter0_d_98_156)">-->
        <!--            <path opacity="0.7"-->
        <!--                  d="M19.2634 11.8995L12.5459 18.617C10.7885 20.3744 7.93926 20.3744 6.1819 18.617V18.617C4.42454 16.8596 4.42454 14.0104 6.1819 12.253L9.71743 8.7175"-->
        <!--                  stroke="#222222" stroke-linecap="round"/>-->
        <!--            <path-->
        <!--                d="M15.0208 7.65685L8.20232 14.4754C7.61653 15.0612 7.61653 16.0109 8.20232 16.5967V16.5967C8.7881 17.1825 9.73785 17.1825 10.3236 16.5967L18.5564 8.36396C19.728 7.19239 19.728 5.29289 18.5564 4.12132V4.12132C17.3848 2.94975 15.4853 2.94975 14.3137 4.12132L9.71754 8.71751"-->
        <!--                stroke="#FFC700" stroke-width="1.5" stroke-linecap="round"/>-->
        <!--          </g>-->
        <!--          <defs>-->
        <!--            <filter id="filter0_d_98_156" x="-3" y="0" width="32" height="32" filterUnits="userSpaceOnUse"-->
        <!--                    color-interpolation-filters="sRGB">-->
        <!--              <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
        <!--              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"-->
        <!--                             result="hardAlpha"/>-->
        <!--              <feOffset dy="4"/>-->
        <!--              <feGaussianBlur stdDeviation="2"/>-->
        <!--              <feComposite in2="hardAlpha" operator="out"/>-->
        <!--              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>-->
        <!--              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_98_156"/>-->
        <!--              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_98_156" result="shape"/>-->
        <!--            </filter>-->
        <!--          </defs>-->
        <!--        </svg>-->
      </div>
      <input class="sendField" placeholder="Введите сообщение" ref="inputArea" v-model="currentMessage">
      <div class="send" @click="sendMessage">
        <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_98_153)">
            <path opacity="0.7"
                  d="M22.993 4.59283C23.2495 3.82321 23.0069 3.07249 22.5076 2.58313L10.917 14.1737C11.0649 14.3191 11.1928 14.4848 11.2962 14.6667L14.3913 20.1099C15.2255 21.5768 17.4028 21.3636 17.9364 19.7626L22.993 4.59283Z"
                  fill="#222222"/>
            <path
                d="M20.5035 2.10331C21.2731 1.84677 22.0238 2.0894 22.5132 2.58872L10.9226 14.1793C10.7773 14.0314 10.6115 13.9035 10.4296 13.8001L4.98643 10.705C3.51949 9.87082 3.73276 7.69356 5.33368 7.15992L20.5035 2.10331Z"
                fill="#FFC700"/>
          </g>
          <defs>
            <filter id="filter0_d_98_153" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_98_153"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_98_153" result="shape"/>
            </filter>
          </defs>
        </svg>

      </div>
    </div>
  </div>
</template>

<script>
import Functions from "@/assets/js/functions";
import MessageComponent from "@/components/chat/MessageComponent";

export default {
  name: "ChatPage",
  components: {MessageComponent},
  data() {
    return {
      title: "",
      messages: [],
      currentMessage: "",
      newMessages: 0,
      chat: null
    }
  },
  created() {
    this.getChat();
    this.getMessages();
  },
  mounted() {
    clearInterval(Functions.interval);
    Functions.interval = setInterval(() => {
      this.getMessages();
    }, 2000)
    this.readMessages();

    document.onkeydown = this.enter;
  },
  methods: {
    enter(e) {
      if (e.keyCode == 13) {
        this.sendMessage();
      }
    },
    getChat() {
      let s = this;
      Functions.ajax("chat/get", {task: s.$route.params.id}, function (json) {

        if (json.type == "success") {
          s.chat = json.data;
          s.title = json.data.title;
          s.newMessages = json.data.countNewMessages;
        }
      })
    },
    bottomScroll() {

      console.log(document.body.scrollHeight)
      window.scrollTo(0, document.body.scrollHeight);
    },
    readMessages() {
      let s = this;
      Functions.fetch("chat/read", {

        task: s.$route.params.id,
        creator: s.chat.creator

      }, function () {
        s.getMessages()
        s.bottomScroll();
      });
    },
    getMessages() {
      let s = this;
      Functions.fetch("chat/messages", {
        task: this.$route.params.id,
        creator: this.chat.creator
      }, function (json) {
        if (json.type == "success") {
          s.messages = json.data;
        }
      })
    },
    sendMessage() {
      let s = this;
      if (!Functions.empty(this.currentMessage)) {
        Functions.ajax("chat/send", {
          task: this.$route.params.id,
          text: this.currentMessage,
          creator: this.chat.creator
        }, function (json) {
          if (json.type != "error") {
            s.currentMessage = ""
            s.readMessages();

            s.$refs.inputArea.focus();
          } else {
            Functions.sendAnswer(json.msg, "error")
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.chatHead {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: #D9D9D9;
}

.header_gray {
  color: #858585;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-left: 20px;
}

.sendBlock {
  background: #222222;
  border-radius: 15px 15px 0px 0px;
  height: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
}

.close {
  width: 24px;
  height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 10px 10px 10px auto;
  background: linear-gradient(144.29deg, rgba(255, 199, 0, 0.47) 13.01%, #FFC700 45.25%);
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.close img {
  width: 100%;
  height: 100%;
}

.messages {
  padding-top: 60px;
  padding-bottom: 120px;
}

.inputSend {
  background: #D9D9D9;
  height: 48px;
  margin: auto;
  width: 90%;
  display: flex;
  border-radius: 24px;
}

.screpka {
  width: 24px;
  height: 24px;
  margin: auto auto auto 5px;
}

.send {
  width: 24px;
  height: 24px;
  margin: auto 5px auto auto;
}

.sendField::placeholder {
  color: #766A6A;
}

.sendField {
  outline: none;
  width: 80%;
  margin: auto;
  border: none;
  background: none;
}
</style>