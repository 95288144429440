<template>
  <div class="header_gray mx-auto">{{ text }}</div>
</template>

<script>
export default {
  props: ["text"],
  name: "HeaderComponent"
}
</script>

<style scoped>
.header_gray {
  color: #858585;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
</style>