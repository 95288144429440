const backend_url = "https://helea.ru/engine/helea/";

export default class Functions {
    static settings = {
        apiKey: 'fbb3cec9-7c52-4009-b4b0-597b8861a89d',
        lang: 'ru_RU',
        version: '2.1'
    }
    static mark;
    static map;
    static interval;
    static enterListener;

    static style;

    static html100() {
        Functions.style = document.createElement("style");
        Functions.style.innerHTML = "html{height:100%;}";
        return Functions.style
    }

    static fetch(url, params = null, func = null, files = null, errorFunc = null) {
        var fd = new FormData();
        fd.append("apikey", "zmRwc9M50gfsKI295vR55B")

        if (Functions.getCookie("token")) {
            fd.append("token", Functions.getCookie("token"))
        }
        console.log(params)

        if (params != null) {
            Object.entries(params).forEach((element) => {
                fd.append(element[0], element[1]);
            });
        }
        if (files != null) {
            let i = 0;
            files.forEach((file) => {
                fd.append("file" + i, file);
                i++;
            });
        }
        let xhr = new XMLHttpRequest();
        xhr.open("post", backend_url + url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    let json = JSON.parse(xhr.response);
                    if (json.code == "102") {
                        Functions.deleteCookie("token")
                        localStorage.clear();
                        Functions.deleteCookie("token")
                        window.location.href = "/auth";
                    }
                    console.log(json)
                    if (func != null) {
                        func(json);
                    }
                } else {
                    if (errorFunc != null) {
                        errorFunc()
                    }
                }
            } else {
                if (errorFunc != null) {
                    errorFunc()
                }
            }
        };
        xhr.send(fd);
    }

    static ajax(url, params = null, func = null, files = null, errorFunc = null) {
        var fd = new FormData();
        fd.append("apikey", "zmRwc9M50gfsKI295vR55B")

        if (Functions.getCookie("token")) {
            fd.append("token", Functions.getCookie("token"))
        }
        console.log(params)

        if (params != null) {
            Object.entries(params).forEach((element) => {
                fd.append(element[0], element[1]);
            });
        }
        if (files != null) {
            let i = 0;
            files.forEach((file) => {
                fd.append("file" + i, file);
                i++;
            });
        }
        let xhr = new XMLHttpRequest();
        xhr.open("post", backend_url + url, false);
        xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    let json = JSON.parse(xhr.response);
                    if (json.code == "102") {
                        Functions.deleteCookie("token")
                        localStorage.clear();
                        Functions.deleteCookie("token")
                        window.location.href = "/auth";
                    }
                    console.log(json)

                    if (func != null) {
                        func(json);
                    }
                } else {
                    if (errorFunc != null) {
                        errorFunc()
                    }
                }
            } else {
                if (errorFunc != null) {
                    errorFunc()
                }
            }
        };
        xhr.send(fd);
    }

    static setCookie(name, value, options = {}) {
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    static deleteCookie(name) {
        Functions.setCookie(name, "", {
            'max-age': -1
        })
    }

    static getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([.$?*|{}\\()[\]/+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static checkPhone() {
        let mask = function (input) {
            var keyCode;

            function mask(event) {
                event.keyCode && (keyCode = event.keyCode);
                var pos = this.selectionStart;
                if (pos < 3) event.preventDefault();
                var matrix = "+7 (___) ___ __ __", i = 0,
                    def = matrix.replace(/\D/g, ""),
                    val = this.value.replace(/\D/g, ""),
                    new_value = matrix.replace(/[_\d]/g, function (a) {
                        return i < val.length ? val.charAt(i++) || def.charAt(i) : a
                    });
                i = new_value.indexOf("_");
                if (i != -1) {
                    i < 5 && (i = 3);
                    new_value = new_value.slice(0, i)
                }
                var reg = matrix.substr(0, this.value.length).replace(/_+/g, function (a) {
                    return "\\d{1," + a.length + "}"
                }).replace(/[+()]/g, "\\$&");
                reg = new RegExp("^" + reg + "$");
                if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) this.value = new_value;
                if (event.type == "blur" && this.value.length < 5) this.value = ""
            }

            input.addEventListener("input", mask, false);
            input.addEventListener("focus", mask, false);
            input.addEventListener("blur", mask, false);
            input.addEventListener("keydown", mask, false)

        };
        mask(document.querySelector('input[type="tel"]'))

    }


    static empty(val) {
        return (val == "" || val == null || val == undefined || val.length == 0)
    }

    static sendAnswer(text, type = "error") {
        let div = document.createElement("div");
        div.classList.add("answer");
        div.classList.add(type);
        div.classList.add("d-flex");
        div.classList.add("flex-center");
        let div2 = document.createElement("div");
        div2.classList.add("circle");
        div2.classList.add("my-auto");
        let img = document.createElement("img");
        if (type == "success")
            img.setAttribute("src", require("@/assets/images/gal.svg"))
        else
            img.setAttribute("src", require("@/assets/images/close.svg"))

        div2.appendChild(img);

        let divtext = document.createElement("div");
        divtext.classList.add("answerText");
        divtext.innerHTML = text;

        let closeDiv = document.createElement("img");
        closeDiv.classList.add("closeAnswer")
        closeDiv.setAttribute("src", require("@/assets/images/close.svg"))

        let timeout;
        div.addEventListener("click", function () {
            clearTimeout(timeout)
            document.body.removeChild(div)
        })


        div.appendChild(div2)
        div.appendChild(divtext)
        div.appendChild(closeDiv)
        document.body.appendChild(div);
        timeout = setTimeout(() => {
            document.body.removeChild(div)
        }, 3000)
    }

}
