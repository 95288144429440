<template>
  <div class="save_menu d-flex p-1"><img @click="edit" class="ml-auto" :src="require('@/assets/images/blackgal.svg')">
  </div>
  <div class="profileImage mx-auto mt-1 d-flex flex-center">
    <img :src="require('@/assets/images/mainMenu/profile.svg')">
<!--    <div class="add_image">+</div>-->
  </div>
  <div class="profileFields w-75 mx-auto d-flex flex-column">
    <input-field :params="fields.nameField"/>
    <input-field :params="fields.phoneField"/>
    <input-field :params="fields.emailField"/>
    <div class="passwords">
      <input-field :params="fields.passwordField"/>
      <input-field :params="fields.replyPasswordField"/>
    </div>
  </div>
  <main-menu/>
</template>

<script>
import MainMenu from "@/items/MainMenu";
import InputField from "@/components/fields/InputField";
import Functions from "@/assets/js/functions";

export default {
  name: "ProfilePage",
  components: {InputField, MainMenu},
  data() {
    return {
      fields: {
        nameField: {
          title: "Имя",
          value: localStorage.getItem("username"),
          error: ""
        },
        phoneField: {
          title: "Телефон",
          type: "tel",
          value: localStorage.getItem("phone"),
          error: ""
        },
        emailField: {
          title: "Почта",
          value: localStorage.getItem("email"),
          error: ""
        },
        passwordField: {
          title: "Пароль",
          type: "password",
          value: "",
          error: ""
        },
        replyPasswordField: {
          title: "Повторите пароль",
          type: "password",
          value: "",
          error: ""
        },
      },
    }
  },
  methods: {
    edit() {
      {
        this.fields.nameField.error = '';
        this.fields.phoneField.error = '';
        this.fields.emailField.error = '';
        this.fields.passwordField.error = '';
        this.fields.replyPasswordField.error = '';

        if (Functions.empty(this.fields.nameField.value)) {
          this.fields.nameField.error = "Поле не может быть пустым"
          return;
        } else if (Functions.empty(this.fields.phoneField.value)) {
          this.fields.phoneField.error = "Поле не может быть пустым"
          return;
        } else if (Functions.empty(this.fields.emailField.value)) {
          this.fields.emailField.error = "Поле не может быть пустым"
          return;
        } else if (this.fields.passwordField.value != this.fields.replyPasswordField.value) {
          this.fields.replyPasswordField.error = "Пароли не совпадают"
        } else {
          let phone = this.fields.phoneField.value.replace("+7", "").replace("(", "").replace(")", "").replace("-", "").replaceAll(/\s/g, '').trim();
          if (phone.length == 11) {
            phone = phone.substring(0, phone.length - 1);
          }

          if (this.fields.phoneField.value.length < 10) {
            this.fields.phoneField.error = "Введите телефон полностью"
            return;
          }


          let obj = {
            name: this.fields.nameField.value,
            phone: phone,
            email: this.fields.emailField.value,
          };

          if (!Functions.empty(this.fields.passwordField.value))
            obj.password = this.fields.passwordField.value;


          let s = this;
          Functions.ajax("user/edit", obj, function (json) {

            if (json.type == "success") {
              s.$router.push("/")
              Functions.sendAnswer("Вы успешно изменили данные", "success")
            } else {
              switch (json.code) {
                case 41:
                  s.fields.phoneField.error = json.msg;
                  break;
                case 42:
                  s.fields.emailField.error = json.msg;
                  break;
                case 40:
                  s.fields.passwordField.error = json.msg;

              }
            }
          })
        }
      }
    }
  },
  mounted() {
    Functions.checkPhone();
  }
}
</script>
<style>
.profileFields .field_input {
  background-color: white !important;
}

.profileFields .field_label {
  color: black;
}
</style>
<style scoped>


.profileImage {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #222;
}

.add_image {
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: linear-gradient(144.29deg, #FFC700 13.01%, #E6A31D 45.25%);
  top: 5px;
  right: 11px;
  border-radius: 50%;
  position: absolute;
}

.passwords {
  margin-top: 25px;
}

.profileImage img {
  width: 60%;
  height: auto;
}

.save_menu {
  border-bottom: 1px solid #cccccc;
  margin: 0 12px;
}


</style>