<template>
  <div class="message" :class="message.position">
    <div class="title">
      <div class="username">
        {{ name }}
      </div>
      <div class="datetime">{{ message.date + " " + message.time }}</div>
    </div>
    <div class="content">
      {{ message.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageComponent",
  props: ["message", "name"],
  methods: {}
}
</script>

<style scoped>
.message {
  color: #F0F0F0;
  background: #222222;
  box-shadow: 0px 8px 18px 2px #0000001A;
  /*min-height: 80px;*/
  display: flex;
  flex-direction: column;
  max-width: 70vw;
  margin: 10px;
}

.message.l {
  border-radius: 24px 24px 24px 0px;
  padding: 16px 20px 16px 25px;
  margin-left: 5px;
}

.message.r {
  border-radius: 24px 24px 0px 24px;
  padding: 16px 25px 16px 20px;
  margin-left: auto;
  margin-right: 5px;
}

.title {
  display: flex;
  margin-bottom: 9px;
}

.datetime {
  margin-left: auto;
}

.content {
  word-wrap: break-word;
}
</style>