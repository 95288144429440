<template>
  <div class="background">
    <LogoType class="mx-auto" :size="160"/>

    <div class="w-75 mx-auto d-flex flex-column my-auto">
      <input-field :params="fields.emailField"/>
      <input-field :params="fields.passwordField"/>
      <div @click="()=>{ this.$router.push('/reset')}" class="yellow-label">Забыли пароль?</div>
    </div>
    <div class="buttons d-flex flex-column">
      <default-button :params="buttons.authButton"/>
      <default-button class="yellow-button" :params="buttons.registerButton"/>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/fields/InputField";
import LogoType from "@/components/LogoType";
import DefaultButton from "@/components/buttons/DefaultButton";
import Functions from "@/assets/js/functions";
import "@/assets/css/fullscreen.css";


export default {
  name: "AuthPage",
  components: {DefaultButton, LogoType, InputField},
  data() {
    return {
      fields: {
        emailField: {
          title: "Почта или телефон",
          value: "",
          error: ""
        },
        passwordField: {
          title: "Пароль",
          type: "password",
          value: "",
          error: ""
        }
      },
      buttons: {
        authButton: {
          value: "Войти",
          action: () => {
            this.auth()
          }
        },
        registerButton: {
          value: "Регистрация",
          action: () => {
            this.$router.push("/register")
          }
        }
      }
    }
  },
  created() {
    if (Functions.getCookie("token") != undefined) {
      this.$router.push("/")

    }
  },
  mounted() {

  },
  methods: {
    auth() {
      this.fields.emailField.error = ""
      this.fields.passwordField.error = ""

      if (Functions.empty(this.fields.emailField.value)) {
        this.fields.emailField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.passwordField.value)) {
        this.fields.passwordField.error = "Поле не может быть пустым"
        return;
      } else {
        let s = this;
        Functions.ajax("auth", {
          login: this.fields.emailField.value,
          password: this.fields.passwordField.value
        }, function (json) {
          if (json.type == "success") {
            let UTCDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()
            Functions.setCookie("token", json.data.token, {
              "expires": UTCDate,
            });
            s.$router.push("/");
            Functions.sendAnswer("Вы успешно авторизовались", "success");
          } else {
            Functions.sendAnswer(json.msg, "error")
          }
        })
      }
    }
  }


}
</script>

<style scoped>
.logo {
  margin-top: 55px;
  margin-bottom: auto;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg_dark_color);
}

.buttons {
  width: 50%;
  margin: auto auto 55px;
}

.buttons .default-button {
  margin: 5px;
}

.buttons .yellow-button {
  background-color: #FFC700;
  color: black;
}

.yellow-label {
  color: #FFC700;
  margin: 12px auto;
}
</style>