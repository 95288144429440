<template>
  <div class="w-80 h-100 mx-auto d-flex flex-column flex-center">
    <header-component :text="task.title"/>
    <div class="grades d-flex flex-center">
      <img height="40" width="40" v-for="grade in grades" :key="grade" @click="updateGrade(grade.key)"
           :src="grade.active==0?require('@/assets/images/grade/review_gray.svg'):require('@/assets/images/grade/review.svg')">
    </div>
    <div class="comment w-100 h-50 mt-5 d-flex flex-column">
      <text-area-field :params="textAreaField"/>
    </div>
    <default-button :params="gradeButton"/>
  </div>
  <main-menu/>
</template>

<script>
import MainMenu from "@/items/MainMenu";
import TextAreaField from "@/components/fields/TextAreaField";
import DefaultButton from "@/components/buttons/DefaultButton";
import "@/assets/css/fullscreen.css"
import Functions from "@/assets/js/functions";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "GradePage",
  components: {HeaderComponent, DefaultButton, TextAreaField, MainMenu},
  data() {
    return {
      task: null,
      grades: [{key: 1, active: 0}, {key: 2, active: 0}, {key: 3, active: 0}, {key: 4, active: 0}, {key: 5, active: 0}],
      grade: 0,
      textAreaField: {
        value: "",
        placeholder: "Введите комментарий",
      },
      gradeButton: {
        value: "Оценить",
        action: () => {
          this.addGrade()
        }
      }
    }
  },
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
    let s = this;
    Functions.ajax("task/get", {
      task: this.$route.params.id
    }, function (json) {

      if (json.type == "success") {
        if (Functions.empty(json.data)) {
          s.$router.push("/");
        } else {
          s.task = json.data
        }
      } else {
        Functions.sendAnswer(json.msg, "error")
        s.$router.push("/");
      }
    });
  },
  methods: {

    addGrade() {
      let s = this;
      Functions.ajax("task/grade", {
        task: this.$route.params.id,
        grade: this.grade,
        comment: this.textAreaField.value
      }, (json) => {
        if (json.type == "success") {
          s.$router.push("/");
        } else {
          Functions.sendAnswer(json.msg, "error");
        }
      })
    },
    updateGrade(count) {
      this.grades.forEach((key) => {
        if (key.key <= count) {
          key.active = 1
        } else {
          key.active = 0
        }
      })
      this.grade = count;
    }
  }
}
</script>
<style>
.comment .field .field_input {
  border: white 1px solid !important;
  font-size: 18px;
  height: 100%;
  padding: 12px;
}

</style>
<style scoped>

.comment .field {
  height: 100%;
}

.default-button {
  width: 100%;
  background-color: #49A562;
  color: white;
}
</style>