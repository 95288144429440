<template>
  <div class="task" @click="()=>{$router.push('/task/'+task.id)}">
    <div class="taskHeader">{{ task.title }}</div>
    <div class="taskAddress">Адрес: {{ task.address }}</div>
    <div class="taskPrice">{{ task.price }} руб.</div>
  </div>
</template>

<script>
import Functions from "@/assets/js/functions";

export default {
  name: "OtherTask",
  props: ["task"],
  data() {
    return {}
  },
  methods: {
    isEmptyUsername() {
      return Functions.empty(this.task.username)
    },
  }
}
</script>

<style scoped>
.task {
  position: relative;
  box-shadow: 0px 8px 18px 2px #0000001A;
  background: #222222;
  padding: 31px 15px 31px 34px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  margin-bottom: 37px;
}

.taskHeader {
  color: #F0F0F0;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
}


.taskAddress {
  color: #F0F0F0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
}

.acceptTask img, .cancelTask img {
  width: 24px;
  height: 24px;
}

.taskPrice {
  background: #FFC700;
  box-shadow: 2px 4px 10px 0px #00000040 inset;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 32px 0px;
  color: #222222;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  width: 128px;
  height: 29px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>