<template>
  <button class="default-button" @click="()=>{parameters.action?parameters.action():''}">
    {{ parameters.value ?? 'Кнопка' }}
  </button>
</template>

<script>
export default {
  name: "DefaultButton",
  props: ["params"],
  data() {
    return {
      parameters: this.params,
    }
  }, watch: {
    parameters: {
      handler(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        this.params = newValue;
      },
    },
    params: {
      deep: true,
      handler(newValue) {
        this.parameters = newValue
      }
    }
  }
}
</script>

<style scoped>
.default-button {
  padding: 11px 25px;
  border-radius: 21px;
  background-color: #D9D9D9;
  border: none;
  outline: none;
}
</style>