<template>
  <div class="background">
    <LogoType class="mx-auto" :size="160"/>

    <div class="w-75 mx-auto d-flex flex-column ">
      <input-field :params="fields.emailField"/>

    </div>
    <div class="buttons d-flex flex-column">
      <default-button class="yellow-button" :params="buttons.resetButton"/>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/fields/InputField";
import LogoType from "@/components/LogoType";
import DefaultButton from "@/components/buttons/DefaultButton";
import Functions from "@/assets/js/functions";
import "@/assets/css/fullscreen.css";

export default {
  name: "ResetPasswordPage",
  components: {DefaultButton, LogoType, InputField},
  data() {
    return {
      fields: {
        emailField: {
          title: "Почта или телефон",
          value: "",
          error: ""
        }
      },
      buttons: {
        resetButton: {
          value: "Сбросить пароль"
        },

      }
    }
  },
  created() {
    if (Functions.getCookie("token") != undefined) {
      this.$router.push("/")
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.logo {
  margin-top: 55px;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg_dark_color);
}

.buttons {
  width: 50%;
  margin: auto auto 55px;
}

.buttons .default-button {
  margin: 5px;
}

.buttons .yellow-button {
  background-color: #FFC700;
  color: black;
}

.yellow-label {
  color: #FFC700;
  margin: 12px auto;
}
</style>