<template>
  <div class="logo" :style="'width:'+size+'px;height:'+size+'px'">
    <img :src="require('@/assets/logo.svg')" style="width: 100%;height: 100%">
  </div>
</template>

<script>
export default {
  name: "LogoType",
  props: ["size"]
}
</script>

<style scoped>

</style>