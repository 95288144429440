<template>
  <MiniProfileMenu/>
  <div class="tasks w-80 mx-auto d-flex flex-column" v-if="otherTasks.length!==0">
    <header-component :text="'Все заявки'"/>
    <other-task v-for="task in otherTasks" :task="task" :key="task.id"/>
  </div>
  <div v-else class="h-100 w-100 d-flex flex-center">
    <h3>Список заявок пока пуст</h3>
  </div>
  <MainMenu/>
</template>

<script>
import MainMenu from "@/items/MainMenu";
import MiniProfileMenu from "@/items/MiniProfileMenu";
import Functions from "@/assets/js/functions";
import OtherTask from "@/components/tasks/OtherTask";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "AllTasksPage",
  components: {HeaderComponent, OtherTask, MiniProfileMenu, MainMenu},
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
    this.getOtherTasksList();
  },
  mounted() {


  },
  data() {
    return {
      otherTasks: []
    }
  },
  methods: {
    getOtherTasksList() {
      let s = this;
      Functions.fetch("task/alltasks",
          {
            limit: s.otherTasks.length,
            count: "25"
          }, function (json) {

            if (!Functions.empty(json.data))
              json.data.forEach(el => {
                s.otherTasks.push(el)
              })
          });
    }
  }
}
</script>

<style scoped>


.tasks {
  margin-bottom: 120px;
}
</style>