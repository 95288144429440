<template>
  <div class="field">
    <label class="field_label" v-if="parameters.title">{{ parameters.title }}</label>
    <input class="field_input" :type="parameters.type??'text'"
           :placeholder="getPlaceholder()"
           @input="inputField"
           v-model="parameters.value">
    <div class="error" v-if="isError">{{ parameters.error }}</div>
  </div>
</template>

<script>
import Functions from "@/assets/js/functions";

export default {
  name: "InputField",
  props: ["params"],
  data() {
    return {
      parameters: this.params
    }
  },
  methods: {
    inputField(event) {
      if (this.params.type == "number") {
        if (Number(event.target.value) == 0) {
          event.target.value = 0
        } else {
          event.target.value = Number(event.target.value);
        }
      }
    },
    isError() {
      if (!Functions.empty(this.params.error)) {
        return 'error';
      } else {
        return '';
      }
    },
    getPlaceholder() {
      return this.parameters.placeholder ?? '';
    }
  },
  watch: {
    parameters: {
      handler(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        this.params = newValue;
      },
    },
    params: {
      deep: true,
      handler(newValue) {
        this.parameters = newValue
      }
    }
  }
}
</script>

<style scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;

}

.field_label {
  color: var(--text-dark_color);
  margin-bottom: 8px;
}

.field_input {
  background-color: var(--field_gray_color);
  height: 38px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.error {
  position: absolute;
  right: 0;
  bottom: 0;
  color: red;
  font-size: 8px;
}


</style>