<template>
  <div class="miniProfileMenuAlternative"></div>
  <div class="miniProfileMenu d-flex flex-center">
    <h5 class="mr-auto">Привет, {{ username }}!</h5>
    <div class="mx-auto miniProfileGrade">
      <GradeItem :size="15" :grade="grade"/>
    </div>
    <div class="miniProfileMoney mb-auto">
      <svg width="20" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="6" width="26" height="20.8" rx="5" stroke="white" stroke-width="1.5"/>
        <path opacity="0.3"
              d="M19 6.5V6.5C19 4.17692 16.8678 2.43898 14.5924 2.90744L5.99174 4.67817C3.66769 5.15665 2 7.20267 2 9.57546L2 13"
              stroke="white" stroke-width="1.5"/>
        <path opacity="0.3" d="M6 17.5H12" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M15 14C15 12.6193 16.1193 11.5 17.5 11.5H22V16.5H17.5C16.1193 16.5 15 15.3807 15 14V14Z"
              stroke="white" stroke-width="1.5"/>
        <path d="M17.5 14H17.7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
import GradeItem from "@/items/GradeItem";
import Functions from "@/assets/js/functions";

export default {
  name: "MiniProfileMenu",
  components: {GradeItem},
  created() {
    let s = this;
    Functions.fetch("user/info", null, function (json) {

      if (json.type == "success") {
        s.username = json.data.name;
        s.grade = json.data.grade;
        localStorage.setItem("username", json.data.name);
        let phone = json.data.phone;
        if (phone.length == 10) {
          phone = "7" + phone;
        }
        localStorage.setItem("phone", phone);
        localStorage.setItem("email", json.data.email);
        localStorage.setItem("grade", json.data.grade);
      }
    })
  },
  data() {
    return {
      username: localStorage.getItem("username"),
      grade: Number(localStorage.getItem("grade"))
    }
  },
  mounted() {
    window.addEventListener("scroll", function () {
      if (document.querySelector(".miniProfileMenu"))
        if (window.pageYOffset >= 40) {
          document.querySelector(".miniProfileMenu").style.position = "fixed";
          document.querySelector(".miniProfileMenuAlternative").classList.add("h");
        } else {
          document.querySelector(".miniProfileMenu").style.position = "relative";
          document.querySelector(".miniProfileMenuAlternative").classList.remove("h");

        }
    })
  }
}
</script>

<style scoped>

.miniProfileMenu {
  height: 40px;
  padding: 12px;
  left: 0;
  right: 0;
  z-index: 3;
  background: #d9d9d9;
}

.miniProfileMoney {
  background: linear-gradient(144.29deg, #FF006B 13.01%, #C6000C 45.25%);
  height: 24px;
  width: 24px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.miniProfileMenuAlternative.h {
  height: 40px;
}
</style>