<template>
  <div class="grades">
    <img v-for="grade in grades" :key="grade.image" :src="grade.image"
         :style="'width:'+size+'px;'+'height:'+size+'px;'">
  </div>
</template>

<script>
export default {
  name: "GradeItem",
  props: ["grade", "size"],
  data() {
    return {
      grades: [
        {image: this.grade >= 1 && this.grade != null ? require("@/assets/images/grade/review.svg") : require("@/assets/images/grade/review_gray.svg")},
        {image: this.grade >= 2 && this.grade != null ? require("@/assets/images/grade/review.svg") : require("@/assets/images/grade/review_gray.svg")},
        {image: this.grade >= 3 && this.grade != null ? require("@/assets/images/grade/review.svg") : require("@/assets/images/grade/review_gray.svg")},
        {image: this.grade >= 4 && this.grade != null ? require("@/assets/images/grade/review.svg") : require("@/assets/images/grade/review_gray.svg")},
        {image: this.grade >= 5 && this.grade != null ? require("@/assets/images/grade/review.svg") : require("@/assets/images/grade/review_gray.svg")},
      ]
    }
  }
}
</script>

<style scoped>

</style>