<template>
  <div class="d-flex flex-column h-100" style="position: relative">
    <div id="map" class="w-100"></div>
    <div class="d-flex flex-column m-1 mx-auto mt-auto">
      <div class="card">
        <div class="taskHeader">{{ task.title }}</div>
        <div class="cardDesk">
          <span>Время</span>
          <div class="cardYellow">{{ task.time }}</div>
        </div>
      </div>
      <div class="taskAddress">
        {{ task.address }}
      </div>
      <div class="card">
        <div class="taskDeck">{{ task.content }}</div>
        <div class="cardDesk">
          <span>Сумма</span>
          <div class="cardYellow">{{ task.price }} руб.</div>
        </div>
      </div>
      <default-button v-if="task.status==0" class="mx-auto" :params="buttons.acceptButton"/>
    </div>
    <div class="close" @click="()=>{$router.push('/')}"><img :src="require('@/assets/images/close.svg')"></div>
  </div>

</template>

<script>
import DefaultButton from "@/components/buttons/DefaultButton";
import Functions from "@/assets/js/functions";
import {loadYmap} from "vue-yandex-maps";
import "@/assets/css/fullscreen.css";

export default {
  name: "TaskPage",
  components: {DefaultButton},
  created() {
    if (Functions.getCookie("token") == undefined) {
      this.$router.push("/auth")
    }
    let s = this;
    Functions.ajax("task/get", {
      task: this.$route.params.id
    }, function (json) {

      if (json.type == "success") {
        if (Functions.empty(json.data)) {
          s.$router.push("/");
        } else {
          s.task = json.data
        }
      } else {
        Functions.sendAnswer(json.msg, "error")
        s.$router.push("/");
      }
    });
  },
  data() {
    return {
      task: {},
      buttons: {
        acceptButton: {
          value: "Принять",
          action: () => {
            this.acceptTask()
          }
        }
      }
    }
  }, methods: {
    async acceptTask() {
      let s = this;
      Functions.ajax("task/accept", {
        "task": s.task.id
      }, function (json) {
        if (json.type == "success") {
          s.$router.push("/");
          Functions.sendAnswer("Заявка успешно принята", "success")
        } else {
          Functions.sendAnswer(json.msg, "error")
        }
      });
    },
  },
  async mounted() {

    await loadYmap(Functions.settings);
    // eslint-disable-next-line no-undef
    Functions.map = new ymaps.Map('map', {
      center: this.task.coords.split(","),
      zoom: 16
    }, {
      searchControlProvider: 'yandex#search'
    })
    let s = this;
    // eslint-disable-next-line no-undef
    let geo = new ymaps.GeoObject({
      // Описание геометрии.
      geometry: {
        type: "Point",
        coordinates: s.task.coords.split(",")
      },
      // Свойства.
      properties: {
        // Контент метки.
        iconContent: s.task.title,
      }
    }, {
      // Опции.
      // Иконка метки будет растягиваться под размер ее содержимого.
      preset: 'islands#blackStretchyIcon',
      // Метку можно перемещать.
      draggable: false
    });

    Functions.map.geoObjects.add(geo)
  }
}
</script>

<style scoped>


.default-button {
  background-color: #49A562 !important;
  border-radius: 21px;
  color: white;
  font-weight: 700;
}

.card {
  position: relative;
  box-shadow: 0 8px 18px 2px #0000001A;
  background: #222222;
  padding: 12px 26px 31px 26px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  margin: 1em;
}

.taskHeader {
  color: white;
  font-weight: 700;
}

.taskDeck {
  color: white;
}

.cardDesk {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #F0F0F0;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.cardYellow {
  background: #FFC700;
  box-shadow: 2px 4px 10px 0px #00000040 inset;
  border-radius: 32px 0px;
  color: #222222;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  width: 128px;
  height: 29px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.taskAddress {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #222222;
  margin: 12px auto;
}

#map {
  min-height: 45vh;
  height: 100%;
  max-height: 100%;
}

.close {
  width: 24px;
  height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background: linear-gradient(144.29deg, #FF0000 13.01%, #D70000 45.25%);
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.close img {
  width: 100%;
  height: 100%;
}
</style>