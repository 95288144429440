<template>
  <div class="field">
    <label class="field_label" v-if="parameters.title">{{ parameters.title }}</label>
    <div class="input_container">
      <input class="field_input" :type="parameters.type??'text'"
             :placeholder="getPlaceholder()"
             v-model="parameters.value" id="suggest">
      <div class="geo" @click="myGeo">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 10.4167C20 15.8445 13.6 21.5 12 21.5C10.4 21.5 4 15.8445 4 10.4167C4 6.04441 7.58172 2.5 12 2.5C16.4183 2.5 20 6.04441 20 10.4167Z"
              stroke="#222222" stroke-width="1.5"/>
          <circle opacity="0.5" r="3" transform="matrix(-1 0 0 1 12 10)" stroke="#222222" stroke-width="1.5"/>
        </svg>
      </div>
    </div>
    <div class="error" v-if="isError">{{ parameters.error }}</div>
  </div>
</template>

<script>
import Functions from "@/assets/js/functions";
import {loadYmap} from "vue-yandex-maps";

export default {
  name: "AddressField",
  props: ["params"],
  data() {
    return {
      parameters: this.params
    }
  },
  methods: {
    isError() {
      if (!Functions.empty(this.params.error)) {
        return 'error';
      } else {
        return '';
      }
    },
    getPlaceholder() {
      return this.parameters.placeholder ?? '';
    },
    async updateMap() {
      // eslint-disable-next-line no-undef
      if (ymaps == undefined) {
        await loadYmap(Functions.settings);
      }
      Functions.map.setCenter(this.params.coords);

      try {
        if (Functions.mark == null) {
          if (this.params.coords != null) {
            // eslint-disable-next-line no-undef,vue/no-mutating-props
            Functions.mark = new ymaps.GeoObject({
              // Описание геометрии.
              geometry: {
                type: "Point",
                coordinates: this.params.coords
              },
              // Свойства.
              properties: {
                // Контент метки.
              }
            }, {
              // Опции.
              // Иконка метки будет растягиваться под размер ее содержимого.
              preset: 'islands#blackStretchyIcon',
              // Метку можно перемещать.
              draggable: true
            });

            Functions.map.geoObjects.add(Functions.mark);
          }
        } else {
          if (this.params.coords != null)
            Functions.mark.geometry.setCoordinates(this.params.coords);
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async myGeo() {
      let s = this;

      if (Functions.map == null) {
        console.log("Карта еще не загружена")
        return;
      }
      // eslint-disable-next-line no-undef
      if (ymaps === undefined) {
        await loadYmap(Functions.settings);
      }
      // eslint-disable-next-line no-undef
      let location = ymaps.geolocation.get({autoReverseGeocode: true, timeout: 2000});
      location.then(
          function (result) {

            let object = result.geoObjects.get(0);
            let geo = object.properties.get('text');
            try {
              s.params.value = geo
              s.params.coords = object.geometry.getCoordinates()

              s.updateMap();
            } catch (ex) {
              console.log(ex)
            }
          },
          function (error) {
            console.log(error)
          }
      );
    }
  },
  watch: {
    parameters: {
      handler(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        this.params = newValue;
      },
    },
    params: {
      deep: true,
      handler(newValue) {
        this.parameters = newValue
      }
    }
  },
  async mounted() {
    await loadYmap(Functions.settings);
    // eslint-disable-next-line no-undef
    let obj = new ymaps.SuggestView('suggest');
    let s = this;
    obj.events.add('select', (item) => {
      // eslint-disable-next-line no-undef
      s.params.value = item.get('item').displayName
      // eslint-disable-next-line no-undef
      ymaps.geocode(s.params.value).then(res => {
        let geo = res.geoObjects.get(0);
        s.params.coords = geo.geometry.getCoordinates();
        s.updateMap();
      })
    })

  }
}
</script>

<style scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
}

.geo {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 50px;
  background-color: #FFC700;
  border-radius: 12px;
  margin-left: auto;
}

.input_container {
  display: flex;
  background-color: var(--field_gray_color);
  border-radius: 12px;
}

.field_label {
  color: var(--text-dark_color);
  margin-bottom: 8px;
}

.field_input {
  width: 100%;
  background-color: var(--field_gray_color);
  height: 38px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.error {
  position: absolute;
  right: 0;
  bottom: 0;
  color: red;
  font-size: 8px;
}


</style>