<template>
  <div class="background">
    <LogoType class="mx-auto" :size="120"/>

    <div class="w-75 mx-auto d-flex flex-column my-auto">
      <input-field :params="fields.nameField"/>
      <input-field :params="fields.phoneField"/>
      <input-field :params="fields.emailField"/>
      <div class="passwords">
        <input-field :params="fields.passwordField"/>
        <input-field :params="fields.replyPasswordField"/>
      </div>
    </div>
    <div class="buttons d-flex flex-column">
      <default-button class="yellow-button" :params="buttons.registerButton"/>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/fields/InputField";
import LogoType from "@/components/LogoType";
import DefaultButton from "@/components/buttons/DefaultButton";
import Functions from "@/assets/js/functions";
import "@/assets/css/fullscreen.css";

export default {
  name: "RegisterPage",
  components: {DefaultButton, LogoType, InputField},
  data() {
    return {
      fields: {
        nameField: {
          title: "Имя",
          value: "",
          error: ""
        },
        phoneField: {
          title: "Телефон",
          type: "tel",
          value: "",
          error: ""
        },
        emailField: {
          title: "Почта",
          value: "",
          error: ""
        },
        passwordField: {
          title: "Пароль",
          type: "password",
          value: "",
          error: ""
        },
        replyPasswordField: {
          title: "Повторите пароль",
          type: "password",
          value: "",
          error: ""
        },
      },
      buttons: {
        registerButton: {
          value: "Регистрация",
          action: () => {
            this.register();
          }
        }
      }
    }
  },
  created() {
    if (Functions.getCookie("token") != undefined) {
      this.$router.push("/")
    }
  },
  methods: {
    register() {
      this.fields.nameField.error = '';
      this.fields.phoneField.error = '';
      this.fields.emailField.error = '';
      this.fields.passwordField.error = '';
      this.fields.replyPasswordField.error = '';

      if (Functions.empty(this.fields.nameField.value)) {
        this.fields.nameField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.phoneField.value)) {
        this.fields.phoneField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.emailField.value)) {
        this.fields.emailField.error = "Поле не может быть пустым"
        return;
      } else if (Functions.empty(this.fields.passwordField.value)) {
        this.fields.passwordField.error = "Поле не может быть пустым"
        return;
      } else if (this.fields.passwordField.value != this.fields.replyPasswordField.value) {
        this.fields.replyPasswordField.error = "Пароли не совпадают"
      } else {
        let phone = this.fields.phoneField.value.replace("+7", "").replace("(", "").replace(")", "").replace("-", "").replaceAll(/\s/g, '').trim();
        if (phone.length == 11) {
          phone = phone.substring(0, phone.length - 1);
        }

        if (this.fields.phoneField.value.length < 10) {
          this.fields.phoneField.error = "Введите телефон полностью"
          return;
        }


        let s = this;
        Functions.ajax("register", {
          name: this.fields.nameField.value,
          phone: phone,
          email: this.fields.emailField.value,
          password: this.fields.passwordField.value
        }, function (json) {
          if (json.type == "success") {
            s.$router.push("/auth")
            Functions.sendAnswer("Вы успешно зарегестрировались", "success")
          } else {
            switch (json.code) {
              case 41:
                s.fields.phoneField.error = json.msg;
                break;
              case 42:
                s.fields.emailField.error = json.msg;
                break;
              case 40:
                s.fields.passwordField.error = json.msg;
                break;
              default:
                Functions.sendAnswer(json.msg, "error");
            }
          }
        })
      }
    }
  },
  mounted() {

    Functions.checkPhone();
  }

}
</script>

<style scoped>
.logo {
  margin-top: 20px;
  margin-bottom: auto;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg_dark_color);
}

.buttons {
  width: 50%;
  margin: auto auto 55px;
}

.buttons .default-button {
  margin: 5px;
}

.buttons .yellow-button {
  background-color: #FFC700;
  color: black;
}

.yellow-label {
  color: #FFC700;
  margin: 12px auto;
}

.passwords {
  margin-top: 25px;
}
</style>